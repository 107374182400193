import * as localForage from 'localforage';
import { encrypt, decrypt } from '../utils';
import { debuggerLogError } from '../utils/debuggerLog';

export const FORAGE_KEY = Object.freeze({
  INITIALIZE: 'initialize',
  SESSION_DATA: 'sessionData',
  LAST_DATA: 'lastData',
  FIN_VERSION: 'finVersion'
});

class LocalForageOPC {
  async setItem(name, data) {
    await localForage.setItem(name, data);
  }

  async getItem(name) {
    return localForage.getItem(name);
  }

  async removeItem(name) {
    await localForage.removeItem(name);
  }

  async clear() {
    await localForage.clear();
  }
}

export class LocalForageService {
  static instance;
  urlParams = new URLSearchParams(window.location.search);
  isProduction = !(
    !window.finEncrypt ||
    this.urlParams.get('debug_encrypt') === '1' ||
    sessionStorage.getItem('debug_encrypt') === '1' ||
    this.urlParams.get('debug_all') === '1' ||
    sessionStorage.getItem('debug_all') === '1'
  );
  localForage = new LocalForageOPC();

  constructor() {
    return LocalForageService.instance || (LocalForageService.instance = this);
  }

  encryptData(data) {
    return encrypt(data);
  }

  async decryptData(nameForage) {
    const encryptedData = await this.localForage.getItem(
      `${nameForage}_encrypted`
    );
    return encryptedData && decrypt(encryptedData);
  }

  async setForage(nameForage, data) {
    try {
      const encryptedKey = `${nameForage}_encrypted`;

      if (this.isProduction) {
        const plainData = await this.localForage.getItem(nameForage);
        if (plainData !== null) {
          await this.localForage.setItem(encryptedKey, this.encryptData(plainData));
          await this.localForage.removeItem(nameForage);
        }
        await this.localForage.setItem(encryptedKey, this.encryptData(data));
      } else {

        const encryptedData = await this.localForage.getItem(encryptedKey);
        if (encryptedData !== null) {
          const decryptedData = decrypt(encryptedData);
          await this.localForage.setItem(nameForage, decryptedData);
          await this.localForage.removeItem(encryptedKey);
        }
        await this.localForage.setItem(nameForage, data);
      }
    } catch (error) {
      debuggerLogError('[Local Forage Error]', error);
    }
  }

  async getForage(nameForage) {
    try {
      const encryptedKey = `${nameForage}_encrypted`;

      if (this.isProduction) {
        const encryptedData = await this.localForage.getItem(encryptedKey);
        if (encryptedData !== null) {
          return decrypt(encryptedData);
        } else {
          const plainData = await this.localForage.getItem(nameForage);
          if (plainData !== null) {
            return plainData;
          }
        }
      } else {
        const plainData = await this.localForage.getItem(nameForage);
        if (plainData !== null) {
          return plainData;
        } else {
          const encryptedData = await this.localForage.getItem(encryptedKey);
          if (encryptedData !== null) {
            return decrypt(encryptedData);
          }
        }
      }

      // If no data found in either format
      return null;
    } catch (error) {
      debuggerLogError('[Local Forage Error]', error);
      return null;
    }
  }

  async clearForage() {
    await this.localForage.clear();
  }
}
